import Axios from 'axios';
import Projects from "../professional/profile/-Projects";
import Project from "../professional/profile/ModalsContent/-Project";
import CreateProject from "../professional/profile/ModalsContent/-CreateProject";

export default {
  components: {
    Project,
    Projects,
    CreateProject,
  },
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      banks: [],
      user: {
        id: this.$route.params.id,
        profile: {},
        works: [],
        received_reviews: [],
        communities: [],
        feedback: {},
      },
      selected_hirings: [],
      modal: null,
      project: null,

    }
  },

  mounted() {
    axios
      .get('/api/v1/users/' + this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          response.data.data.feedback = {
            sent: false,
          };
          this.user = response.data.data;
          // console.log(this.user);
        } else {
          console.log(response.data.error);
        }
    });

    axios
      .get('/api/v1/hirings?provider=' + this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          for (var i = 0; i < response.data.data.length; i++) {
            response.data.data[i].open = false;
          }
          this.selected_hirings = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });

    axios
      .get("/api/v1/banks", {
        headers: {
          Authorization: "Bearer " + this.TOKEN,
        },
      })
      .then((response) => {
        if (!response.data.error) {
          this.banks = response.data.data;
        }
      });

  },

  methods: {

    sumApprovedValues(hirings) {
      var sum = 0;
      hirings.forEach((hiring, index) => {
        sum += this.costByPlan(hiring.approved_value, hiring.plan);
      });
      return sum;
    },

    toCurrency(val) {
      return (val/100).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2});
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
          value = value / 0.8928571429;
          break;
        case 5:
          value = value;
          break;
        case 6:
          value = value / 0.70;
          break;
        case 7:
          value = value / 0.80;
          break;
        default:
        value = value / 0.85;
      }
      return value;
    },

    handleModal(type) {
      this.modal = type;
    },

    setProject(project) {
      this.project = project;
    },

    approveUser(decision) {
      let data = {
        decision: decision,
      };
      axios
        .put('/api/v1/users/' + this.$route.params.id + '/user-status', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            this.$router.go();
          } else {
            console.log(response.data.error);
          }
      });
    },

    sendComment(comment) {
      let _this = this;
      let data = {
        comment: comment.body,
        user: _this.user.id,
      };

      axios
        .post('/api/v1/comments', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.user.feedback.sent = true;
          } else {
            console.log(response.data.error);
          }
      });
    },

    loginAsUser() {
      axios
        .get('/api/v1/users/' + this.$route.params.id + '/login-as-user', {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            var _this = this;
            // save token for future use
            _this.$store.commit('setToken', response.data.data.access_token);
            // save me for future use
            _this.me = response.data.data.user;
            _this.$store.commit('setMe', response.data.data.user);
            // redirect user to dashboard
            if (_this.me.admin) {
              _this.$router.push({ path: '/dashboard/admin' })
            } else if (_this.me.company_id) {
              _this.$router.push({ path: '/dashboard/client' })
            } else {
              _this.$router.push({ path: '/dashboard/professional' })
            }
          } else {
            console.log(response.data.error);
          }
      });
    },

    toggleOpen(hiring) {
      hiring.open = !hiring.open;
      if (hiring.open) hiring.preselected = [];
      if (hiring.open) this.cardOpen(hiring);
    },

    // hirings
    cardOpen(hiring) {
      axios
        .get('/api/v1/users/select-for-hiring/' + hiring.id, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            if (!response.data.data.length) {
              hiring.noOne = true;
            }
            hiring.preselected = response.data.data;
          } else {
            console.log(response.data.error);
          }
      });
    },

    addCreator: function (param) {
      axios
        .get('/api/v1/users/' + param.id, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            param.hiring.preselected.push(response.data.data);
          } else {
            console.log(response.data.error);
          }
      });
    },

    approveBriefing: function (param) {
      var ids = param.selected.map( function( elem ) { return elem.id});
      var data = {
        selected: ids,
        reveal: param.reveal,
      };
      axios
        .put('/api/v1/hirings/' + param.hiring.id + '/approve-briefing', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            param.hiring = response.data.data;
            this.$router.go();
          }
      });
    },

    progressBriefing: function (hiring) {
      var data = {};
      axios
        .put('/api/v1/hirings/' + hiring.id + '/hiring-status', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            hiring = response.data.data;
            this.$router.go();
          }
      });
    },

    cancelHiring: function (hiring) {
      axios
        .put('/api/v1/hirings/' + hiring.id + '/cancel', {}, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            hiring = response.data.data;
            this.$router.go();
          }
      });
    },

    updateSpecialities(data) {

      let _this = this;
      let post = {
        specialities: data.specialities,
        mainspeciality: data.mainspeciality,
      }

      console.log(post);

      axios
        .put('/api/v1/users/' + data.id, post, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.errors = response.data.error;
            _this.syncing = false;
          } else {
            _this.syncing = false;
            this.$router.go();
          }
      });
    },

  }

}
